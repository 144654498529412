import { faAward, faExchange, faHeart, faHistory, faSearch, faShoppingBag, faSignIn, faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BACKEND_Image_URL } from '../../env';
import Logo from "../../images/desertsdroppshiper.png";
import Menu from "../../images/menu.png";
import NotFound from "../../images/notFound.webp";
import Dropdown from "../Dropdown";
import "./index.css";
function Navbar({ openSidebar, AllCategory, WishlistData, setSearchQuery, searchQuery, AllProducts }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const isActive = (path) => location.pathname === path;

    useEffect(() => {
        if (searchQuery?.trim()) {
            const suggestions = [
                // ...(AllCategory?.map(category => category.name) || []),
                ...(AllProducts?.map(product => product) || []),
            ];
            const filtered = suggestions.filter(item => typeof item.title === 'string' && item.title.toLowerCase().includes(searchQuery.toLowerCase()));
            console.log('Filtered Suggestions:', filtered);
            setFilteredSuggestions(filtered);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    }, [searchQuery, AllCategory, AllProducts]);

    return (
        <div style={{ background: "#06142e" }}>
            <div className="nav-container">
                <div className="nav-main">
                    <img src={Logo} alt="Logo" width={"20%"} className="nav-logo" onClick={() => navigate("/")} />
                    <div className="nav-input-dropdown">
                        <Dropdown categories={AllCategory?.map(category => category.name)} />
                        <div style={{ width: "1px", height: "10px", background: "#ebebeb", marginLeft: 10, marginRight: 10 }}></div>
                        <input onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                            onFocus={() => setShowSuggestions(true)}
                            placeholder='Search' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        <button>
                            <FontAwesomeIcon icon={faSearch} color="white" />
                        </button>
                        {showSuggestions && (
                            <div className="suggestions-dropdown">
                                <div className="suggestions-category">
                                    {/* <strong>Suggestions</strong> */}
                                    {filteredSuggestions.slice(0, 8).map((suggestion, index) => (
                                        <div className="suggestions-product" onClick={() => navigate("/ProductDetail", { state: suggestion })}>
                                            <img src={suggestion?.images?.length > 0 ?
                                                suggestion?.images[0]?.startsWith("products/http") || suggestion?.images[0]?.startsWith("products/https")
                                                    ? suggestion?.images[0]?.replace("products/", "")
                                                    : `${BACKEND_Image_URL}${suggestion?.images[0]}` : NotFound
                                            } alt={suggestion.title} />
                                            <p
                                                key={index}
                                                className="suggestion-item"
                                                onClick={() => {
                                                    setSearchQuery(suggestion);
                                                    setShowSuggestions(false);
                                                    // navigate(`/search?query=${suggestion}`);
                                                }}
                                            >
                                                {suggestion.title}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='nav-login-and-wishlist'>
                        <div className="nav-wishlist-container" onClick={() => navigate("/StoreIntegration")}>
                            <div className="nav-heart-container">
                                <FontAwesomeIcon icon={faStore} className="nav-heart" />
                            </div>
                            <p className="nav-wishlist-text">Store Integration</p>
                        </div>
                        <div className="nav-wishlist-container" onClick={() => navigate("/WishList")}>
                            <div className="nav-heart-container">
                                <FontAwesomeIcon icon={faHeart} className="nav-heart" />
                            </div>
                            <p className="nav-wishlist-text">Wish List ({WishlistData?.length || 0})</p>
                        </div>
                        <div className="nav-wishlist-container" onClick={() => navigate("/Login")}>
                            <div className="nav-heart-container">
                                <FontAwesomeIcon icon={faSignIn} className="nav-heart" />
                            </div>
                            <p className="nav-wishlist-text">Login</p>
                        </div>
                        {/* <p className='nav-login-text'>Login</p> */}
                    </div>
                </div>
            </div>

            <div className="nav-links-container">
                <div style={{ width: "85%", margin: "0 auto", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <ul className="nav-link">
                        <li className={isActive("/") ? "active" : ""} onClick={() => navigate("/")}>Home</li>
                        <li className={isActive("/About") ? "active" : ""} onClick={() => navigate("/About")}>About us</li>
                        <li className={isActive("/Contact") ? "active" : ""} onClick={() => navigate("/Contact")}>Contact us</li>
                        <li className={isActive("/PrivacyPolicy") ? "active" : ""} onClick={() => navigate("/PrivacyPolicy")}>Privacy Policy</li>
                        <li className={isActive("/Services") ? "active" : ""} onClick={() => navigate("/Services")}>Services</li>
                    </ul>
                    <img src={Menu} alt="Menu" className="nav-menu" onClick={openSidebar} />
                    <div className="sidebar-input-dropdown">
                        {/* <SidebarDropdowns /> */}
                        {/* <div style={{ width: "1px", height: "10px", background: "#ebebeb", marginLeft: 10, marginRight: 10 }}></div> */}
                        <input onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                            onFocus={() => setShowSuggestions(true)}
                            placeholder='Search' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

                        <button>
                            <FontAwesomeIcon icon={faSearch} color="white" />
                        </button>
                        {showSuggestions && (
                            <div className="suggestions-dropdown">
                                <div className="suggestions-category">
                                    {/* <strong>Suggestions</strong> */}
                                    {filteredSuggestions.slice(0, 8).map((suggestion, index) => (
                                        <div className="suggestions-product" onClick={() => navigate("/ProductDetail", { state: suggestion })}>
                                            <img src={suggestion?.images?.length > 0 ?
                                                suggestion?.images[0]?.startsWith("products/http") || suggestion?.images[0]?.startsWith("products/https")
                                                    ? suggestion?.images[0]?.replace("products/", "")
                                                    : `${BACKEND_Image_URL}${suggestion?.images[0]}` : NotFound
                                            } alt={suggestion.title} />
                                            <p
                                                key={index}
                                                className="suggestion-item"
                                                onClick={() => {
                                                    setSearchQuery(suggestion);
                                                    setShowSuggestions(false);
                                                    // navigate(`/search?query=${suggestion}`);
                                                }}
                                            >
                                                {suggestion.title}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='order-cart-container'>
                        <div className="nav-cart-container" onClick={() => navigate("/Cart")}>
                            <FontAwesomeIcon icon={faShoppingBag} color="white" />
                            <p> MY CART</p>
                        </div>
                        <div className="nav-history-container" onClick={() => navigate("/orderHistory")}>
                            <FontAwesomeIcon icon={faHistory} color="white" />
                            <p>Order History</p>
                        </div>
                        <div className="nav-history-container" onClick={() => navigate("/Transaction")}>
                            <FontAwesomeIcon icon={faExchange} color="white" />
                            <p>Transaction History</p>
                        </div>
                        <div className="nav-history-container" onClick={() => navigate("/Reward")}>
                            <FontAwesomeIcon icon={faAward} color="white" />
                            <p>Reward</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
