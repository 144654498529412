import { faBalanceScale, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import "./index.css";
import OrderComplete from "../../images/package.png";
import OrderCancelled from "../../images/cancel-order.png";
import OrderProccess from "../../images/process.png";
import ChartComponent from '../../components/Chart';
import { useNavigate } from 'react-router-dom';
import PaymentDropdown from '../../components/PaymentDropdown';
import { withAppContext } from '../../context';
import axios from 'axios';
import { BACKEND_URL } from '../../env';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

function Reward({ AllCategory, WishlistData, instagramData, AllProducts, }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [user, setUser] = useState({});
    const [email, setEmail] = useState("")
    const [whatsapp, setWhatsapp] = useState("")
    const [storeName, setStoreName] = useState("")
    const [cnic, setCnic] = useState("")
    const [bankName, setBankName] = useState("")
    const [accountTitle, setAccountTitle] = useState("")
    const [accountNumbers, setAccountNumbers] = useState("")
    const [iban, setIban] = useState("")
    const [method, setMethod] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [accountName, setAccountName] = useState("")
    const [amount, setAmount] = useState("")
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const openSidebar = () => {
        setSidebarOpen(true);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };
    const handleUpdateProfile = async () => {
        if (!email) {
            toast.error('Please Email Input');
            return
        }
        if (!storeName) {
            toast.error('Please Store Name Input');
            return
        }
        if (!storeName) {
            toast.error('Please Password Input');
            return
        }
        if (!whatsapp) {
            toast.error('Please Whatsapp Input');
            return
        }
        if (!cnic) {
            toast.error('Please Cnic Input');
            return
        }
        if (!bankName) {
            toast.error('Please Bank Name Input');
            return
        }
        if (!accountTitle) {
            toast.error('Please Account Tite Input');
            return
        }
        if (!accountNumbers) {
            toast.error('Please Account Number Input');
            return
        }
        if (!iban) {
            toast.error('Please Account iban Input');
            return
        }
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            email: email,
            whatsapp: whatsapp,
            storeName: storeName,
            cnic: cnic,
            bankNumber: bankName,
            accountTitle: accountTitle,
            accountNumber: accountNumbers,
            iban: iban,
        });

        const requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        try {
            const response = await fetch(
                `${BACKEND_URL}/users/${storedLoginData.id}`,
                requestOptions
            );
            const result = await response.json();

            setLoading(false);

            if (result.status === 400) {
                toast.error(result.message);
            } else {
                window.location.reload()
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again.');
            setLoading(false);
        }

    };
    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/users/${storedLoginData.id}`, {
                    headers: {
                        token: storedLoginData.token,
                    }
                });
                setUser(response.data.user);
            } catch (error) {
                console.error('Failed to fetch user data', error);
            }
        };
        fetchUser()
    }, []);
    useEffect(() => {
        // Fetch orders for the logged-in user

        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/orders/user/${user?.email}`, {
                    headers: {
                        token: storedLoginData.token,
                    }
                });
                setOrders(response.data);
                // Fetch tracking information from Finix
                // fetchTrackingInfo(response.data);
            } catch (error) {
                console.error('Failed to fetch orders', error);
            }
        };

        // const fetchTrackingInfo = async (orders) => {
        //     try {
        //         const trackingResponses = await Promise.all(
        //             orders.map(order =>
        //                 axios.get(`https://finix-api-url.com/track/${order.orderId}`, {
        //                     headers: {
        //                         token: storedLoginData.token, // Add any required authentication
        //                     }
        //                 })
        //             )
        //         );
        //         const trackingData = trackingResponses.reduce((acc, response, index) => {
        //             acc[orders[index].orderId] = response.data;
        //             return acc;
        //         }, {});
        //         setTrackingInfo(trackingData);
        //     } catch (error) {
        //         console.error('Failed to fetch tracking information', error);
        //     }
        // };
        if (user) {

            fetchOrders();
        }
    }, [user]);

    useEffect(() => {
        setEmail(user?.email)
        setWhatsapp(user?.whatsapp)
        setStoreName(user?.storeName)
        setBankName(user?.bankNumber)
        setAccountTitle(user?.accountTitle)
        setCnic(user?.cnic)
        setAccountNumbers(user?.accountNumber)
        setIban(user?.iban)
    }, [user])

    const getFilteredOrders = () => {
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth(); // 0-based (0 = January, 11 = December)

        return orders.filter(order => {
            const orderDate = new Date(order.orderDate);
            return orderDate.getFullYear() === currentYear && orderDate.getMonth() === currentMonth;
        });
    };
    // Calculate the counts of different order statuses
    const getOrderCounts = () => {
        let completed = 0;
        let cancelled = 0;
        let inProcess = 0;
        const filteredOrders = getFilteredOrders();
        filteredOrders?.forEach(order => {
            if (order.status === 'delivered') {
                completed++;
            } else if (order.status === 'cancelled') {
                cancelled++;
            } else if (order.status === 'pending' || order.status === 'shipped') {
                inProcess++;
            }
        });

        return { completed, cancelled, inProcess };
    };

    const { completed, cancelled, inProcess } = getOrderCounts();

    // Calculate the balance based on completed orders
    const calculateBalance = (completedOrders) => {
        if (completedOrders >= 100 || completedOrders === 100)
            return Math.floor(completedOrders * 0.5);
    };

    const balance = calculateBalance(completed);

    const handleSubmit = () => {
        setLoading(true)
        // const formData = new FormData();
        let obj = {
            accountName: accountName,
            accountNumber: accountNumber,
            amount: amount,
            method: method,
            storeName: user.storeName,
            email: user.email,
            whatsapp: user.whatsapp,
        }
        const config = {
            method: "post",
            url: `${BACKEND_URL}/reward`,
            data: obj,
            headers: {
                token: storedLoginData.token
            }
        };
        axios(config)
            .then((res) => {
                setLoading(false);
                toast.success(`Your Request Submit`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                });
                setModalOpen(!modalOpen)
                setAccountName("")
                setAccountNumber("")
                setAmount("")
                setMethod("")
            })
            .catch((res) => {
                setLoading(false);
                toast.error(`Invalid error`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                });
            });
    }
    const calculateProgress = (completedOrders) => {
        if (completedOrders >= 100) {
            return 100; // If completed orders reach 100 or more, cap the progress at 100%
        }
        return Math.floor((completedOrders / 100) * 100); // Calculate percentage of completed orders
    };

    const progress = calculateProgress(completed);
    return (
        <div>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} AllProducts={AllProducts} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            {modalOpen ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "20px 0" }}>
                    <div className='withdarwal-modal'>
                        <PaymentDropdown onSelect={setMethod} />
                        <input className='reward-account-name' placeholder='Enter Account Name' value={accountName} onChange={(e) => setAccountName(e.target.value)} />
                        <input className='reward-account-name' placeholder='Enter Account Number' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                        <input className='reward-account-name' placeholder='Enter Amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
                        <button className='reward-submit-btn' onClick={() => handleSubmit()}>{loading ? <CircularProgress size={20} style={{ color: "#fff" }} /> : "Submit"}</button>
                    </div>
                </div>
            ) : updateOpen ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", margin: "20px 0" }}>
                    <h1 className="reward-main-heading">Update Profile</h1>
                    <div className="register-form-container">
                        <div className="form-data">
                            <div className="register-input-div">
                                <label className="lable">Store Name*</label>
                                <div className="name-input-div">
                                    <input
                                        type="text"
                                        placeholder="Store Name"
                                        // className="register-input"
                                        className="register-input"
                                        value={storeName}
                                        onChange={(e) => setStoreName(e.target.value)}
                                    // required
                                    />
                                </div>
                            </div>
                            <div className="register-input-div">
                                <label className="lable">E-mail*</label>
                                <div className="name-input-div">
                                    <input
                                        type="text"
                                        placeholder="E-mail"
                                        // className="register-input"
                                        className="register-input"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-data">
                            <div className="register-input-div">
                                <label className="lable">Whatsapp*</label>
                                <div className="name-input-div">
                                    <input
                                        type="type"
                                        placeholder="Whatsapp Number - 03160117561 nospace"
                                        // minLength={11}
                                        // maxLength={11}
                                        className="register-input"
                                        value={whatsapp}
                                        onChange={(e) => {
                                            // if (e.target.value.length <= 11) {
                                            setWhatsapp(e.target.value.replace(/\D/g, '')); // Only allow numbers
                                            // }
                                        }}
                                    // onChange={(e) => setWhatsapp(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="register-input-div">
                                <label className="lable">CNIC*</label>
                                <div className="name-input-div">
                                    <input
                                        type="number"
                                        placeholder="CNIC"
                                        // className="register-input"
                                        className="register-input"
                                        value={cnic}
                                        onChange={(e) => setCnic(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-data">
                            <div className="register-input-div">
                                <label className="lable">Bank Name*</label>
                                <div className="name-input-div">
                                    <input
                                        // type="number"
                                        placeholder="Bank Name"
                                        // className="register-input"
                                        className="register-input"
                                        value={bankName}
                                        onChange={(e) => setBankName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="register-input-div">
                                <label className="lable">Account Title*</label>
                                <div className="name-input-div">
                                    <input
                                        type="text"
                                        placeholder="Account Title"
                                        // className="register-input"
                                        className="register-input"
                                        value={accountTitle}
                                        onChange={(e) => setAccountTitle(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-data">
                            <div className="register-input-div">
                                <label className="lable">Account Number*</label>
                                <div className="name-input-div">
                                    <input
                                        type="number"
                                        placeholder="Account Number"
                                        // className="register-input"
                                        className="register-input"
                                        value={accountNumbers}
                                        onChange={(e) => setAccountNumbers(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="register-input-div">
                                <label className="lable">IBAN*</label>
                                <div className="name-input-div">
                                    <input
                                        // type="number"
                                        placeholder="IBAN 24 Digits Without Space"
                                        className="register-input"
                                        value={iban}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 24) {
                                                setIban(e.target.value); // Only allow numbers
                                            }
                                        }}
                                        // onChange={(e) => setIban(e.target.value)}
                                        minLength={24}
                                        maxLength={24}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="register-btn-container">
                            <button className="register-btn" onClick={() => handleUpdateProfile()}>{loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Update"}</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="reward-container">
                    <h1 className="reward-main-heading">Reward Detail</h1>
                    <div className="reward-blance-info-container">
                        <ul>
                            {/* <li><strong>Name:</strong> {user.name || 'Loading...'}</li> */}
                            <li><strong>Email:</strong> {user.email || 'Loading...'}</li>
                            <li><strong>Whatsapp:</strong> {user.whatsapp || 'Loading...'}</li>
                        </ul>
                        <div className='balance-withdraw-container'>
                            <div className="reward-balance-container" onClick={() => setUpdateOpen(!updateOpen)}>
                                <p>Update Profile</p>
                            </div>
                            <div className="reward-balance-container">
                                <p>Balance {balance || 0} AED</p>
                                <FontAwesomeIcon icon={faBalanceScale} color="White" />
                            </div>
                            <div className="reward-balance-container" onClick={() => setModalOpen(!modalOpen)}>
                                <p>Withdrawal</p>
                                <FontAwesomeIcon icon={faMoneyBill} color="White" />
                            </div>
                        </div>
                    </div>
                    <div className="reward-orders-container">
                        <div className='reward-order'>
                            <div className='reward-order-image-text'>
                                <div className='reward-order-image-container'>
                                    <img src={OrderComplete} alt='' width={30} />
                                </div>
                                <div>
                                    <p>Order Completed</p>
                                    <p className='reward-number'>{completed}</p>
                                </div>
                            </div>
                        </div>
                        <div className='reward-order'>
                            <div className='reward-order-image-text'>
                                <div className='reward-order-image-container'>
                                    <img src={OrderCancelled} alt='' width={30} />
                                </div>
                                <div>
                                    <p>Order Cancelled</p>
                                    <p className='reward-number'>{cancelled}</p>
                                </div>
                            </div>
                        </div>
                        <div className='reward-order'>
                            <div className='reward-order-image-text'>
                                <div className='reward-order-image-container'>
                                    <img src={OrderProccess} alt='' width={30} />
                                </div>
                                <div>
                                    <p>Order In Process</p>
                                    <p className='reward-number'>{inProcess}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <marquee className="reward-headline" direction="right">Your {progress}% Reward is Complete. Hurry up, Reward Completed After 100 Order Complete Per Order 0.5 AED Reward</marquee>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <div className='reward-chart-container'>
                            <p>{progress}% Progress</p>
                            <ChartComponent completed={progress} />
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button className='reward-claim-btn' onClick={() => navigate("/RewardCelebration")} disabled={completed < 10}>Claim</button>
                    </div>
                </div>
            )}
            <Footer instagramData={instagramData} />
        </div>
    );
}

export default withAppContext(Reward);
