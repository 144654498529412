import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/sidebar';
import { useEffect, useState } from 'react';
import { withAppContext } from '../../context';
import axios from 'axios';
import { BACKEND_URL } from '../../env';

function Transaction({ AllCategory, WishlistData, instagramData, AllProducts, }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [allTransaction, setAllTransaction] = useState([]);
    const [user, setUser] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));

    const openSidebar = () => {
        setSidebarOpen(true);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    useEffect(() => {

        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/orders/user/${user?.email}`, {
                    headers: {
                        token: storedLoginData.token,
                    }
                });
                setAllTransaction(response.data);
                // Fetch tracking information from Finix
                // fetchTrackingInfo(response.data);
            } catch (error) {
                console.error('Failed to fetch orders', error);
            }
        };
        if (user.email) {
            fetchOrders()
        }
    }, [user.email]); // Depend on 'user.email'
    // useEffect(() => {
    //     if (user.email) {
    //         const fetchAllTransactions = () => {
    //             axios
    //                 .get(`${BACKEND_URL}/reward/${user.email}`, {
    //                     headers: {
    //                         token: storedLoginData.token,
    //                     }
    //                 })
    //                 .then((data) => {
    //                     console.log(data.data);
    //                     setAllTransaction(data.data.reward ? [data.data.reward] : []); // Convert to array if it's a single object
    //                 })
    //                 .catch((error) => {
    //                     console.error("Error fetching transactions:", error);
    //                 });
    //         };
    //         fetchAllTransactions();
    //     }
    // }, [user.email]); // Depend on 'user.email'

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/users/${storedLoginData.id}`, {
                    headers: {
                        token: storedLoginData.token,
                    }
                });
                setUser(response.data.user);
            } catch (error) {
                console.error('Failed to fetch user data', error);
            }
        };

        fetchUser();
    }, []); // Fetch user data once on component mount
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };
    return (
        <div>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} AllProducts={AllProducts} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div style={{ width: "85%", margin: "30px auto" }}>
                <h1 style={{ fontFamily: "Kanit", marginBottom: "20px", fontSize: "20px" }}>Transaction History</h1>
                <TableContainer component={Paper} className='table-scroll'>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }}>ID</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Date</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Email</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Store Name</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Account Name</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Amount</TableCell>
                                <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "700" }} align="left">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allTransaction.length > 0 ? (
                                allTransaction.map((row) => (
                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">{row.id}</TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">
                                            {formatDate(row.orderDate)}
                                        </TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#878a99" }} align="left">{row.email}</TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">{user.storeName}</TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">{user.accountTitle}</TableCell>
                                        <TableCell style={{ fontFamily: "Kanit", fontSize: "14px", fontWeight: "500", color: "#222" }} align="left">{row.profileMargin} AED</TableCell>
                                        <TableCell align="left">
                                            <div style={{ background: "#cdf7ec", textAlign: "center", color: "rgb(6, 214, 160)", fontSize: "12px", fontWeight: "500", fontFamily: "Kanit", borderRadius: "12px", padding: "4px" }}>{row.profitStatus}</div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">No transactions available</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Footer instagramData={instagramData} />
        </div>
    );
}

export default withAppContext(Transaction);