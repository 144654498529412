import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import { withAppContext } from "../../context";
import "./index.css";
function Privacy({ AllCategory, WishlistData, instagramData, AllProducts, }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }
    return (
        <>
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} AllProducts={AllProducts} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className="services-maindiv">

                <div className="services">
                    <div className="services-heading-container">
                        <h3>Services</h3>
                        {/* <h6>Last Modified: July 22 24</h6> */}
                    </div>
                    <div className="services-div">
                        <h6 style={{ fontSize: "16px", fontWeight: "700" }}>1. Shopify Store Design 15k</h6>
                        <h6 style={{ fontSize: "16px", fontWeight: "700" }}>2. Marketing 20k</h6>
                        <h6 style={{ fontSize: "16px", fontWeight: "700" }}>3. Store Handling 15k</h6>
                        <h6 style={{ fontSize: "16px", fontWeight: "700" }}>4. Social Media Handling 20k</h6>
                        <h6 style={{ fontSize: "16px", fontWeight: "700" }}>5. Wordpress Website 35k</h6>
                        <h6 style={{ fontSize: "16px", fontWeight: "700" }}>6. Whatsapp Handling 10k</h6>
                        <h6 style={{ fontSize: "16px", fontWeight: "700" }}>7. Tiktok Business Center Account 15k</h6>
                        <h6 style={{ fontSize: "18px", fontWeight: "700" }}>Contact Us</h6>
                        <ul>
                            <li><strong>Email:</strong> desertsdropshipperinfo@gmail.com</li>
                            <li><strong>Phone:</strong> +97 1562779487</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer instagramData={instagramData} />
        </>
    );
}

export default withAppContext(Privacy);
