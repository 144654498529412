import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CountryDropdowns from "../../components/CountryDropdown";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import { withAppContext } from "../../context";
import { BACKEND_Image_URL, BACKEND_URL } from "../../env";
import ThankYou from '../../images/thank-you.png';
import "./index.css";
import NotFound from "../../images/notFound.webp";
function CheckoutDetail({ AllCategory, WishlistData, instagramData, AllProducts, }) {
    const navigate = useNavigate()
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showThankYouPopup, setShowThankYouPopup] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        profitMargin: '',
        address1: '',
        country: 'UAE',
        city: '',
    });

    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    // setCartItems(storedCart);
    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }
    useEffect(() => {
        // Scroll to the top of the page on component mount
        window.scrollTo(0, 0);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCitySelect = (selectedCity) => {
        setFormData({ ...formData, city: selectedCity });
    };
    const deliveryCharges = 18;
    const calculateTotalPrice = () => {
        return storedCart.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
    };

    const total = calculateTotalPrice();
    const subTotal = total + deliveryCharges + (parseFloat(formData.profitMargin) || 0);
    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));
    const handleSubmit = async (e) => {
        e.preventDefault();

        const totalAmount = subTotal;
        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_URL}/orders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: storedLoginData.token,
                },
                body: JSON.stringify({
                    ...formData,
                    totalAmount,
                    profileMargin: formData.profitMargin,
                    products: storedCart.map((item) => ({
                        id: item.id,
                        quantity: item.quantity,
                    })),
                    userId: storedLoginData.id
                }),
            });

            if (response.ok) {
                const result = await response.json();
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    profitMargin: '',
                    address1: '',
                    country: 'UAE',
                    city: '',
                })
                setLoading(false)
                setShowThankYouPopup(true);

                // Hide the popup after 5 seconds
                setTimeout(() => {
                    setShowThankYouPopup(false);
                    // Navigate to confirmation or success page
                    navigate('/');
                }, 5000);
                localStorage.removeItem("cart")
            } else {
                setLoading(false)
                const errorData = await response.json();
                console.error('Order creation failed:', errorData.error);
            }
        } catch (error) {
            setLoading(false)
            console.error('Error:', error);
        }
    };

    return (
        <div className="main-checkout-container">
            <Navbar openSidebar={openSidebar} AllCategory={AllCategory} WishlistData={WishlistData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} AllProducts={AllProducts} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <form className="main-container" onSubmit={handleSubmit}>
                <div className="checkout-form-container">
                    <div className="form-data">
                        <p className="form-title">
                            <b className="text-uppercase">YOUR PERSONAL DETAILS</b>
                        </p>
                    </div>
                    <div className="form-data">
                        <div className="second-input-div">
                            <label className="lable">First Name</label>
                            <div className="name-input-div">
                                <input
                                    type="text"
                                    placeholder="Enter Your First Name "
                                    name="firstName"
                                    className="simple-input"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="second-input-div">
                            <label className="lable">Last Name</label>
                            <div className="name-input-div">
                                <input
                                    type="text"
                                    placeholder="Enter Your Last Name "
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    className="simple-input"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-data">
                        <div className="second-input-div">
                            <label className="lable">Email</label>
                            <div className="name-input-div">
                                <input
                                    type="email"
                                    placeholder="Enter Your Email"
                                    name="email"
                                    className="simple-input"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="second-input-div">
                            <label className="lable">Phone Number</label>
                            <div className="name-input-div">
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    placeholder="Enter Your Phone Number"
                                    className="simple-input"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-data">
                        <div className="second-input-div">
                            <label className="lable">Profit Margin</label>
                            <div className="name-input-div">
                                <input
                                    type="text"
                                    name="profitMargin"
                                    placeholder="Enter Your Profit Margin"
                                    className="simple-input"
                                    value={formData.profitMargin}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-data">
                        <p className="form-title">
                            <b className="text-uppercase">SHIPPING ADDRESS</b>
                        </p>
                    </div>
                    <div className="form-data">
                        <div className="second-input-div">
                            <label className="lable">Country</label>
                            <div className="name-input-div">
                                <input
                                    type="text"
                                    name="country"
                                    placeholder="Country"
                                    className="simple-input"
                                    value={formData.country}
                                    readOnly
                                />
                            </div>
                        </div>
                        {/* <div className="form-data"> */}
                        <div className="second-input-div">
                            <label className="lable">City</label>
                            <div className="name-input-div">
                                <CountryDropdowns onSelect={handleCitySelect} />
                            </div>
                        </div>
                    </div>
                    <div className="form-data">
                        <div className="second-input-div">
                            <label className="lable">Address</label>
                            <div className="name-input-div">
                                <input
                                    type="text"
                                    name="address1"
                                    placeholder="Address"
                                    className="simple-input"
                                    value={formData.address1}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="place-order-container">
                    {storedCart.map((e, i) => (
                        <div className="place-order-wrapper">
                            <div className="place-order-product" key={i}>
                                <img
                                    src={e.images?.length > 0 ?
                                        e?.images[0]?.startsWith("products/http") || e?.images[0]?.startsWith("products/https")
                                            ? e?.images[0]?.replace("products/", "")
                                            : `${BACKEND_Image_URL}${e?.images[0]}` : NotFound
                                    }
                                    // src={
                                    //     `${BACKEND_Image_URL}${e.images[0]}`
                                    // }
                                    className="place-order-itme-image"
                                    width="50px"
                                    height="50px"
                                />

                                <div className="order-placed-text-description">
                                    <p className="place-order-text">{e.title}</p>
                                    <p className="place-order-description">
                                        {" "}
                                        {e.descrtion}
                                    </p>
                                    <p className="place-order-quantity">

                                        Price:
                                        {e.price}
                                    </p>
                                    <p className="place-order-quantity">
                                        {" "}
                                        Qty: {e.quantity}
                                    </p>
                                </div>
                            </div>

                            <div className="place-order-price-container">
                                <p className="place-order-price">
                                    {e.price * e.quantity} Aed
                                </p>
                            </div>
                        </div>
                    ))}
                    <div>
                        <div className="total-main-check">
                            <span className="total-price-text">Total</span>
                            <span className="total-price-number">
                                {total} Aed
                            </span>
                        </div>
                        <div className="total-main-check">
                            <span className="total-price-text">Delivery Charges </span>
                            <span className="total-price-number">
                                {deliveryCharges} Aed
                            </span>
                        </div>
                        <div className="total-main-check">
                            <span className="total-price-text">Profit Margin </span>
                            <span className="total-price-number">
                                {formData.profitMargin || 0} Aed
                            </span>
                        </div>
                        <div className="total-main-check">
                            <span className="grandtotal-price-text">Sub Total</span>
                            <span className="grandtotal-price-numnber">
                                {subTotal} Aed
                            </span>
                        </div>
                    </div>
                    <button type="submit" className="place-order-button ">
                        {loading ? <CircularProgress size={22} style={{ color: "white" }} /> : "Place Order"}
                    </button>
                </div>
            </form>
            {showThankYouPopup && (
                <div className="login-popup-modal">
                    <div className="overlay-modal">
                    </div>
                    <div className="login-popup">
                        <div>
                            <img src={ThankYou} style={{ width: "150px" }} />
                        </div>
                    </div>
                </div>
            )}
            <Footer instagramData={instagramData} />
        </div>
    );
}
export default withAppContext(CheckoutDetail);